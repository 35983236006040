import React, { Component } from 'react';
import axios from 'axios';
// import DatePicker from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";

export default class CreateCar extends Component {
  constructor(props) {
    super(props);

    this.onChangeMake = this.onChangeMake.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangePoints = this.onChangePoints.bind(this);
    this.onChangeImgurl = this.onChangeImgurl.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      make: '',
      model: '',
      description: '',
      points: 0,
      imgurl: ''
    }
  }

  componentDidMount() {
    // axios.get('http://api.coolcargame.xyz/users/')
    //   .then(response => {
    //     if (response.data.length > 0) {
    //       this.setState({
    //         users: response.data.map(user => user.username),
    //         username: response.data[0].username
    //       })
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
  }

  onChangeMake(e) {
    this.setState({
      make: e.target.value
    })
  }

  onChangeModel(e) {
    this.setState({
      model: e.target.value
    })
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    })
  }

  onChangePoints(e) {
    this.setState({
      points: e.target.value
    })
  }

  onChangeImgurl(e) {
    this.setState({
      imgurl: e.target.value
    })
  }

  onSubmit(e) {
    e.preventDefault();

    const car = {
      make: this.state.make,
      model: this.state.model,
      description: this.state.description,
      points: Number(this.state.points),
      imgurl: this.state.imgurl,
    }

    console.log(car);

    axios.post('http://api.coolcargame.xyz/cars/add', car)
      .then(res => console.log(res.data));

    window.location = '/';
  }

  render() {
    return (
    <div>
      <h3>Create New Car</h3>
      <form onSubmit={this.onSubmit}>
        {/* <div className="form-group"> 
          <label>Username: </label>
          <select ref="userInput"
              required
              className="form-control"
              value={this.state.username}
              onChange={this.onChangeUsername}>
              {
                this.state.users.map(function(user) {
                  return <option 
                    key={user}
                    value={user}>{user}
                    </option>;
                })
              }
          </select>
            </div> */}
        <div className="form-group"> 
          <label>Make: </label>
          <input  type="text"
              required
              className="form-control"
              value={this.state.make}
              onChange={this.onChangeMake}
              />
        </div>
        <div className="form-group"> 
          <label>Model: </label>
          <input  type="text"
              required
              className="form-control"
              value={this.state.model}
              onChange={this.onChangeModel}
              />
        </div>
        <div className="form-group"> 
          <label>Description: </label>
          <input  type="text"
              className="form-control"
              value={this.state.description}
              onChange={this.onChangeDescription}
              />
        </div>
        <div className="form-group">
          <label>Points: </label>
          <input 
              type="text" 
              className="form-control"
              value={this.state.points}
              onChange={this.onChangePoints}
              />
        </div>
        <div className="form-group">
          <label>Image URL: </label>
          <input 
              type="text" 
              className="form-control"
              value={this.state.imgurl}
              onChange={this.onChangeImgurl}
              />
        </div>
        {/* <div className="form-group">
          <label>Date: </label>
          <div>
            <DatePicker
              selected={this.state.date}
              onChange={this.onChangeDate}
            />
          </div>
        </div> */}

        <div className="form-group">
          <input type="submit" value="Create Car" className="btn btn-primary" />
        </div>
      </form>
    </div>
    )
  }
}