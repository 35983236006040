import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { render } from 'react-dom';

const titleStyle = {
  position: 'relative',
  top: '-130px',
  fontSize: '20px',
  color: 'dimgrey',
}

const pointsStyle = {
  position: 'relative',
  right: '-130px',
  bottom: '90px',
  fontSize: '42px',
  color: 'dimgrey',
}

const gridStyle = {
  display: 'flex',
  flexWrap: 'wrap',
}

const carStyle = {
  flex: '1 1 2%',
}

const headerStyle = {
  textAlign: 'center',
}

const Car = props => (
  <div 
    style={carStyle}
    href="#" onClick={() => { props.incrementScore(props.car.points) }}
    >
      <img 
        src={ "/img/cars/" + props.car.imgurl } 
        alt={ props.car.make + " " + props.car.model } 
        width="200" 
        title={ props.car.make + " " + props.car.model } 
        car={ props.car } 
        points={ Number(props.car.points) } 
        key={ props.car._id }
      />
      <div style={titleStyle}>{ props.car.make + " - " + props.car.model }</div>
      <div style={pointsStyle}>{ props.car.points }</div>
  </div>
);

export default class Game extends Component {
  constructor(props) {
    super(props);
    this.incrementScore = this.incrementScore.bind(this);

    this.state = {
      cars: [],
      gamescore: 0,
    };
  }

  componentDidMount() {
    axios.get('https://api.coolcargame.xyz/cars')
      .then(response => {
        this.setState({ cars: response.data })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  incrementScore(points) {
    this.setState({ gamescore: this.state.gamescore + points });
  }

  carList() {
    return this.state.cars.map(currentcar => {
      return <Car incrementScore={this.incrementScore} car={currentcar} points={currentcar.points} key={currentcar._id}/>;
    })
  }

  // incrementScore={this.incrementScore(this.state.gamescore)}

  render() {
    return (
      <div>
        <div>
          <div style={headerStyle}>
            <h3>The Cool Car Game</h3>
            <p>Get in the car. Have the driver start driving. Watch for cool cars. Rack up points.</p>
          </div>
          <div><h3>Score: {this.state.gamescore} </h3></div>
        </div>
        <br/>
        <div style={gridStyle}>
          { this.carList() }
          </div>
      </div>
    )
  }
}