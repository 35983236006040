import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Car = props => (
  <tr>
    <td>{props.car.make}</td>
    <td>{props.car.model}</td>
    <td>{props.car.description}</td>
    <td>{props.car.points}</td>
    <td>{props.car.imgurl}</td>
    <td>
      <Link to={"/editcar/"+props.car._id}>edit</Link> | <a href="#" onClick={() => { props.deleteCar(props.car._id) }}>delete</a>
    </td>
  </tr>
)

export default class CarsList extends Component {
  constructor(props) {
    super(props);

    this.deleteCar = this.deleteCar.bind(this)

    this.state = {cars: []};
  }

  componentDidMount() {
    axios.get('https://api.coolcargame.xyz/cars/')
      .then(response => {
        this.setState({ cars: response.data })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  deleteCar(id) {
    axios.delete('https://api.coolcargame.xyz/cars/'+id)
      .then(response => { console.log(response.data)});

    this.setState({
      cars: this.state.cars.filter(el => el._id !== id)
    })
  }

  carList() {
    return this.state.cars.map(currentcar => {
      return <Car car={currentcar} deleteCar={this.deleteCar} key={currentcar._id}/>;
    })
  }

  render() {
    return (
      <div>
        <h3>Cars</h3>
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Make</th>
              <th>Model</th>
              <th>Description</th>
              <th>Points</th>
              <th>Image URL</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { this.carList() }
          </tbody>
        </table>
      </div>
    )
  }
}