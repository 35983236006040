import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route} from "react-router-dom";

import Navbar from "./components/navbar.component";
import Game from "./components/game.component";
import CarsList from "./components/cars-list.component";
import EditCar from "./components/edit-car.component";
import CreateCar from "./components/create-car.component";
import CreateUser from "./components/create-user.component";
import EditUser from "./components/edit-user.component";
import UsersList from "./components/users-list.component";

function App() {
  return (
    <Router>
      <div className="container">
      <br/>
      <Route path="/" exact component={Game} />
      <Route path="/cars" exact component={CarsList} />
      <Route path="/editcar/:id" component={EditCar} />
      <Route path="/car" component={CreateCar} />
      <Route path="/user" component={CreateUser} />
      <Route path="/users" component={UsersList} />
      <Route path="/edituser/:id" component={EditUser} />
      <br/>
      <Navbar />
      </div>
    </Router>
  );
}

export default App;
